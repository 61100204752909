html {
  overflow-x: hidden;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  overflow-x: hidden;
}

@keyframes banner-scroll {
  from {
    right: 0;
  }
  to {
    right: 25%;
  }
}

@keyframes wave {
  0% {
    background-position-x: 0;
  }
  to {
    background-position-x: 100%;
  }
}

.wave {
  animation: wave 10s ease-in-out infinite alternate;
}

.banner {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  animation-name: banner-scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.click-btn {
  background-size: cover;
  position: absolute;
  background-position: top;
}

.click-btn:active {
  background-position: bottom;
}
